//============================================
// Intersectionobserver
//============================================

// const img = document.querySelectorAll(".fw--image:not(.slider--image)");
// const imgScale = document.querySelectorAll(".scroll--image");
// const slideInFromRight = document.querySelectorAll(".slideInRight");

// if (typeof IntersectionObserver === "undefined") {
//   img.forEach(i => {
//     i.className.remove("scaled");
//   });
// } else {
//   const scaleInOptions = {
//     threshold: 0,
//     rootMargin: "0px 0px -100px 0px"
//   };

//   const scaleInOnScroll = new IntersectionObserver(
//     (entries, scaleInOnScroll) => {
//       entries.forEach(entry => {
//         if (!entry.isIntersecting) {
//           entry.target.classList.add("scaled");
//           return;
//         } else {
//           entry.target.classList.remove("scaled");
//           //scaleInOnScroll.unobserve(entry.target);
//         }
//       });
//     },
//     scaleInOptions
//   );
//   const slideIn = new IntersectionObserver((entries, slideIn) => {
//     entries.forEach(entry => {
//       if (!entry.isIntersecting) {
//         entry.target.classList.add("out");
//         return;
//       } else {
//         entry.target.classList.remove("out");
//       }
//     });
//   }, scaleInOptions);

//   img.forEach(image => {
//     scaleInOnScroll.observe(image);
//   });
//   imgScale.forEach(image => {
//     scaleInOnScroll.observe(image);
//   });
//   slideInFromRight.forEach(image => {
//     slideIn.observe(image);
//   });
// }

// function headerScroll() {
//   const st = $(this).scrollTop();
//   const windowHeight = $(window).height();
//   const headerImage = $(".fullheight--header");

//   headerImage.css({
//     top: st / 4
//   });
// }
// $(window).scroll(debounce(headerScroll, 0, 20));


window.addEventListener('scroll', function() {
	var scrollPosition = window.scrollY;
	var imageItem = document.querySelector('.fw--image-item');
	if(!imageItem) return;
  
	if (scrollPosition > 0) {
	  var translateY = scrollPosition * 0.2; // 20% des Scroll-Wegs
	  imageItem.style.transform = 'translateY(' + translateY + 'px)';
	} else {
	  imageItem.style.transform = 'none';
	}
  });