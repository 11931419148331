// $("#header--slider").slick({
//   arrows: true,
//   prevArrow:
//     '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
//   nextArrow:
//     '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'
// });
$(document).ready(function() {
  // Synched Slider
  $(".slider-nav").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: false,
    // asNavFor: ".slider-for",
    dots: false,
    focusOnSelect: false,
    arrows: true,
    prevArrow:
      '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
    nextArrow:
      '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'
  });
  $(".markenslider__home").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: false,
    // asNavFor: ".slider-for",
    dots: true,
    focusOnSelect: false,
	  arrows: false,
	  autoplay: true,
	  autoplaySpeed: 5000
  });

  $(".slider-for").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    asNavFor: ".slider-nav",
    arrows: true,
    prevArrow:
      '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
    nextArrow:
      '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'
  });

  // Synched Ende

  $(".grid--slider").slick({
    arrows: true,
    prevArrow:
      '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
    nextArrow:
      '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'
  });

  $(".as-slider").slick({
    asNavFor: ".as-slider2",
    arrows: true,
    prevArrow:
      '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
    nextArrow:
      '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'
  });

  $("#optoslider").slick({
    arrows: true,
    prevArrow:
      '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
    nextArrow:
      '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'
  });
  $("#storeslider").slick({
    arrows: false,
    autoplaySpeed: 5000,
    autoplay: true,
    dots: true,
    prevArrow:
      '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
    nextArrow:
      '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'
  });
});



$(document).ready(function() {
  $(".as-slider2").slick({
    arrows: false,
    centerMode: true,
    centerPadding: "0px",
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1
        }
      }
    ]
  });
});

function initSlider() { 
  if (window.innerWidth <= 800) {
    if (!$(".news--slider").hasClass("slick-slider")) { 
      $(".news--slider").slick({
        // mobileFirst: true,
        arrows: false,
        dots: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000
      })
    }
  } else { 
    if ($(".news--slider").hasClass('slick-slider')) {
      $(".news--slider").slick("unslick")
    } else { 
      return
    }
  }
}
$(document).ready(function () {
  initSlider()
})

window.addEventListener('resize', initSlider)



$(document).ready(function() {
  $(".manufaktur-slider").slick({
    arrows: false,
    dots: false,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    prevArrow:
      '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
    nextArrow:
      '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'

  });
});